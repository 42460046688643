import React, { useEffect, useState } from 'react';
import { getNewsData } from '../../api';
import moment from 'moment';
import './news.css';
const News = () => {
  const [tableData, setTableData] = useState();
  const today = moment().format('MM-DD-YYYY');
  useEffect(() => {
    console.log(today);
    getNewsData().then((data) => {
      setTableData(data);
      console.log(data);
    });
  }, []);
  const bg_images = [
    'city',
    'concert',
    'dj',
    'ghetto',
    'graffiti',
    'graffiti2',
    'harlem',
    'hd-wallpaper',
    'hip-hop',
    'new',
    'street-dancer',
    'tunnel',
  ];
  return (
    <div className='news_container'>
      <h1 className='title_history'>
        News heute {moment().format('DD.MM.YYYY')}{' '}
      </h1>
      <div className='container_singlenews'>
        {tableData &&
          tableData.map((el, i) => (
            <a href={el.link} target='_blank'>
              <div key={i} className='container_news'>
                <figure className={'figure_news'}>
                  <img
                    src={require(`../../images/bgImages/${
                      bg_images[i % bg_images.length]
                    }.jpg`)}
                    alt='profile-sample1'
                    className={'background'}
                  />
                  <div className={'row_news'}>
                    <div lang='de' className='eintrag_news'>
                      <h2>{el.title}</h2>
                      <h4>{el.blatt}</h4>
                    </div>
                  </div>
                </figure>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default News;
