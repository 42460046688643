import React, { useState } from 'react';
import './infobox.css';

import { RiQuestionFill, RiCloseCircleFill } from 'react-icons/ri';
import { AiOutlineLine, AiOutlineArrowRight } from 'react-icons/ai';
const InfoBox = ({ listOfSymbol }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className='container_infobox'>
      {showInfo ? (
        <RiCloseCircleFill
          color='white'
          size={50}
          cursor={'pointer'}
          onClick={() => setShowInfo(!showInfo)}
        />
      ) : (
        <RiQuestionFill
          color='white'
          size={50}
          cursor={'pointer'}
          onClick={() => setShowInfo(!showInfo)}
        />
      )}

      <div
        className='container_infotext'
        style={showInfo ? { opacity: 1 } : { opacity: 0 }}
      >
        <table className='info_table'>
          <thead>
            <tr>
              <th title='Symbol'>Symbol</th>
              <th title='Bedeutung'>Bedeutung</th>
            </tr>
          </thead>
          <tbody>
            {listOfSymbol.map((x, i) => (
              <tr key={i}>
                <td>{x.symbol}</td>
                <td>{x.bedeutung}</td>
              </tr>
            ))}
            {/* <tr>
              <td>
                <AiOutlineLine color={'#51c81b'} size={20} />
              </td>
              <td>Folgen sich gegenseitig</td>
            </tr>
            <tr>
              <td>
                <AiOutlineArrowRight color={'white'} size={20} />
              </td>
              <td>Einseitiges Folgen</td>
            </tr>
            <tr>
              <td>
                <button className='bySize_info'>Size</button>
              </td>
              <td>Größe nach Followerzahl</td>
            </tr>
            <tr>
              <td>
                <button className='bySize_info'>Alle</button>
              </td>
              <td>Zeigt alle Verbindungen an</td>
            </tr>
            <tr>
              <td>
                <button className='bySize_info'>In</button>
              </td>
              <td>Zeigt nur Rapper an die dem ausgewählten Rapper folgen</td>
            </tr>
            <tr>
              <td>
                <button className='bySize_info'>out</button>
              </td>
              <td>Zeigt nur Rapper an denen der ausgewählte Rapper folgt</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InfoBox;
