import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getHistoryData } from '../../api/index';
import './history.css';
import 'font-awesome/css/font-awesome.min.css';
import { VscChromeClose } from 'react-icons/vsc';
import { ImArrowRight } from 'react-icons/im';

const History = () => {
  const [tableData, setTableData] = useState();
  const [availDates, setAvailDates] = useState();
  const [fullData, setFullData] = useState();
  //const today = moment().format('MM-DD-YYYY');

  const today = moment(new Date()).format('MM-DD-YYYY');
  let availableDates = [];
  useEffect(() => {
    console.log(today);
    getHistoryData().then((data) => {
      setAvailDates(Object.keys(data).reverse());
      setFullData(data);
      setTableData(data[today]);
      console.log(availableDates);
    });
  }, []);
  useEffect(() => {}, []);
  return (
    <div className='container_history'>
      <div className={'figure_history'}>
        <div className='container_title'>
          <h1 className='title_history'>Daily Fitna</h1>
          <select
            name='dates'
            className='history_select'
            onChange={(e) => {
              setTableData(fullData[e.target.value]);
            }}
          >
            {availDates &&
              availDates.map((x, i) => (
                <option key={i} value={x}>
                  {x}
                </option>
              ))}
          </select>
        </div>

        <div className='containertable'>
          {tableData &&
            tableData.map((el, i) => (
              <div key={i} className='row'>
                <div className='imageContainerL'>
                  <img
                    src={require(`../../images/${el.source}.jpg`)}
                    className='image_history'
                  />
                  <div className='eintragName'>{el.source}</div>
                </div>
                <div className='symbolContainer'>
                  {el.type == 'follow' ? (
                    <ImArrowRight
                      color='#0ba9ca'
                      size={60}
                      className='arrowIcon'
                    />
                  ) : (
                    <>
                      <ImArrowRight
                        color='#0ba9ca'
                        size={60}
                        className='arrowIcon'
                      />
                      <VscChromeClose color='red' size={60} className='Xicon' />
                    </>
                  )}
                </div>
                <div className='imageContainerR'>
                  <img
                    src={require(`../../images/${el.target}.jpg`)}
                    className='image_history'
                  />
                  <div className='eintragName'>{el.target}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default History;
