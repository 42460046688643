import React, { useEffect, useState, useRef } from 'react';
import './followSection.css';

import { getArtistsData } from '../../api/index';
import { SearchBar, Graph, Artist2, InfoBox } from '../../components';
import { AiOutlineLine, AiOutlineArrowRight } from 'react-icons/ai';

const FollowSection = () => {
  const [artistCard, setArtistCard] = useState();
  const [graph, setGraph] = useState();
  const [initialGraph, setinitialGraph] = useState();
  const [minMaxFoll, setMinMax] = useState();
  const [init, setInit] = useState(0);
  const [filter, setFilter] = useState();
  const [artistList, setArtistList] = useState();
  const [initArtistList, setInitArtistList] = useState();
  const [clickOutside, setClickOutside] = useState(true);
  const [distance, setDistance] = useState(300);
  const [bySize, setBySize] = useState(false);
  const listOfSymbol = [
    {
      symbol: <AiOutlineLine color={'#51c81b'} size={20} />,
      bedeutung: 'Folgen sich gegenseitig',
    },
    {
      symbol: <AiOutlineArrowRight color={'white'} size={20} />,
      bedeutung: 'Einseitiges Folgen',
    },
    {
      symbol: <button className='bySize_info'>Size</button>,
      bedeutung: 'Größe nach Followerzahl',
    },
    {
      symbol: <button className='bySize_info'>Alle</button>,
      bedeutung: 'Zeigt alle Verbindungen an',
    },
    {
      symbol: <button className='bySize_info'>In</button>,
      bedeutung: 'Zeigt nur Rapper an die dem ausgewählten Rapper folgen',
    },
    {
      symbol: <button className='bySize_info'>Out</button>,
      bedeutung: 'Zeigt nur Rapper an denen der ausgewählte Rapper folgt',
    },
  ];
  const filterGraph = () => {
    setDistance(30);
    console.log(distance);
    if (filter == 'all') {
      const filteredLinks = initialGraph.links.filter(
        (link) =>
          link.source.id == artistCard.id || link.target.id == artistCard.id
      );
      const connectedNodeIds = filteredLinks.map((x) => x.source.id);
      const connectedNodeIdsInc = filteredLinks.map((x) => x.target.id);
      setGraph({
        nodes: initialGraph.nodes.filter(
          (node) =>
            artistCard.id == node.id ||
            connectedNodeIds.includes(node.id) ||
            connectedNodeIdsInc.includes(node.id)
        ),
        links: filteredLinks,
      });
    } else if (filter == 'in') {
      const filteredLinks = initialGraph.links.filter(
        (link) => link.target.id == artistCard.id && link.dir != 2
      );
      const connectedNodeIds = filteredLinks.map((x) => x.source.id);
      const connectedNodeIdsInc = filteredLinks.map((x) => x.target.id);
      setGraph({
        nodes: initialGraph.nodes.filter(
          (node) =>
            artistCard.id == node.id ||
            connectedNodeIds.includes(node.id) ||
            connectedNodeIdsInc.includes(node.id)
        ),
        links: filteredLinks,
      });
    } else if (filter == 'out') {
      const filteredLinks = initialGraph.links.filter(
        (link) => link.source.id == artistCard.id && link.dir != 2
      );
      const connectedNodeIds = filteredLinks.map((x) => x.source.id);
      const connectedNodeIdsInc = filteredLinks.map((x) => x.target.id);

      setGraph({
        nodes: initialGraph.nodes.filter(
          (node) =>
            artistCard.id == node.id ||
            connectedNodeIds.includes(node.id) ||
            connectedNodeIdsInc.includes(node.id)
        ),
        links: filteredLinks,
      });
    }
  };

  useEffect(() => {
    getArtistsData().then((data) => {
      setGraph(data);
      setinitialGraph(data);
      setArtistCard(data.nodes[0]);
      const FollowerNumbers = data.nodes.map((x) => x.follower_count);
      setMinMax({
        min: Math.min(...FollowerNumbers),
        max: Math.max(...FollowerNumbers),
      });

      setArtistList(data.nodes.map((x) => x.name));
      setInitArtistList(data.nodes.map((x) => x.name));
    });
  }, []);
  const isMounted = useRef(false);

  useEffect(() => {
    console.log(init);
    if (init == 2) {
      if (graph) {
        filterGraph();
        setFilter('all');
      }
    } else {
      setInit(init + 1);
    }
  }, [artistCard]);

  useEffect(() => {
    if (isMounted.current) {
      if (filter != 'none') {
        filterGraph();
      }
    } else {
      isMounted.current = true;
    }
  }, [filter]);

  return (
    <div id='container_followsection' className='container_followsection'>
      <div className='container_heading'>
        <h1>Wer Folgt wem?</h1>
        <h2>Ein Kunstwerk basierend auf echten Daten...</h2>
      </div>

      <InfoBox listOfSymbol={listOfSymbol} />
      <div className='sidebar'>
        {artistCard && (
          <div
            className='container_artistcart'
            onClick={() => {
              setClickOutside(true);
            }}
          >
            {' '}
            <Artist2
              artistCard={artistCard}
              setGraph={setGraph}
              initialGraph={initialGraph}
              setArtistCard={setArtistCard}
              setFilter={setFilter}
              setClickOutside={setClickOutside}
            />
          </div>
        )}
        <button className='bySize' onClick={() => setBySize(!bySize)}>
          {bySize ? 'Network' : 'Size'}
        </button>
        <div className='searchbar'>
          <SearchBar
            initArtistList={initArtistList}
            setArtistList={setArtistList}
            artistList={artistList}
            setArtistCard={setArtistCard}
            setClickOutside={setClickOutside}
            clickOutside={clickOutside}
            initialGraph={initialGraph}
            artistCard={artistCard}
          />
        </div>
      </div>

      <div
        className='container_graph'
        id='graph'
        onClick={() => {
          setClickOutside(true);
        }}
      >
        <Graph
          className='graph'
          graph={graph}
          minMaxFoll={minMaxFoll}
          setArtistCard={setArtistCard}
          setFilter={setFilter}
          setClickOutside={setClickOutside}
          distance={distance}
          bySize={bySize}
        />
      </div>
    </div>
  );
};

export default FollowSection;
