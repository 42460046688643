import React, { useState } from 'react';
import './artist2.css';

import moment from 'moment';

const Artist2 = ({ artistCard, setFilter, setGraph, initialGraph }) => {
  const twentyFor =
    artistCard.followingHistory[artistCard.followingHistory.length - 1].diff;

  const sum = artistCard.followingHistory
    .map((x) => x.diff)
    .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <div className='container_artist'>
      <figure className={'figure_artist'}>
        <img
          src={require(`../../images/${artistCard.userName}.${
            artistCard.userName == 'capital_bra' ||
            artistCard.userName == 'haftbefehl'
              ? 'png'
              : 'jpg'
          }`)}
          alt='profile-sample1'
          className={'background'}
        />

        <div className={'figure_content'}>
          <div className='resetcontainer'>
            <div className='resetButton'>
              <div
                className='outer'
                onClick={() => {
                  console.log('set');
                  console.log(artistCard);
                  setFilter('none');
                  setGraph(initialGraph);
                }}
              >
                <div className='inner'>
                  <label>Reset</label>
                </div>
              </div>
            </div>
          </div>
          <div className='imgcontainer'>
            <img
              src={require(`../../images/${artistCard.userName}.${
                artistCard.userName == 'capital_bra' ||
                artistCard.userName == 'haftbefehl'
                  ? 'png'
                  : 'jpg'
              }`)}
              alt='profile-sample1'
              className={'profile_pic'}
            />
          </div>

          <div lang='de' className='artist_infobox'>
            <h2>{artistCard.name}</h2>
            <h4>({artistCard.userName})</h4>
            <h4>Instagram: {artistCard.follower_count} Follower</h4>
            <div className='changes_cont'>
              <span>
                Letzen 24h
                <p
                  style={
                    twentyFor >= 0 ? { color: '#39FF14' } : { color: '#F72119' }
                  }
                >
                  {twentyFor >= 0 ? '+' : ''}
                  {twentyFor}(
                  {Math.round(100 * (twentyFor / artistCard.follower_count))}%)
                </p>
              </span>
              <span>
                Seit{' '}
                {moment(new Date(artistCard.followingHistory[0].date)).format(
                  'DD.MM.YYYY'
                )}
                <p
                  style={sum >= 0 ? { color: '#39FF14' } : { color: '#F72119' }}
                >
                  {sum >= 0 ? '+' : ''}
                  {sum}(
                  {Math.round(10000 * (sum / artistCard.follower_count)) / 100}
                  %)
                </p>
              </span>
            </div>
          </div>
          <div className='button-group'>
            <button
              className='change_button'
              onClick={() => {
                console.log('set');

                setFilter('all');
              }}
            >
              Alle
            </button>
            <button
              className='change_button'
              onClick={() => {
                console.log('set');
                setFilter('in');
              }}
            >
              In
            </button>
            <button
              className='change_button'
              onClick={() => {
                console.log('set');
                setFilter('out');
              }}
            >
              Out
            </button>
          </div>
        </div>
      </figure>
    </div>
  );
};

export default Artist2;
