import React, { useEffect, useState, useRef, useCallback } from 'react';
import './graph.css';
import ForceGraph2D from 'react-force-graph-2d';
//import { forceCollide } from 'https://cdn.skypack.dev/d3-force@3';
const Graph = ({
  graph,
  minMaxFoll,
  setArtistCard,
  setFilter,
  setClickOutside,
  distance,
  bySize,
}) => {
  const fgRef = useRef();
  const handle = (node) => {
    //fgRef.current.centerAt(node.x, node.y);
    fgRef.current.zoomToFit(15, 50);
  };

  useEffect(() => {
    //const distance = graph.links.length > 100 ? 30 : 300;
    fgRef.current.d3Force('link').distance((link) => distance);
    //fgRef.current.d3Force('collide', forceCollide(30));
    fgRef.current.d3Force('center');
  }, []);

  return (
    <ForceGraph2D
      onBackgroundClick={() => {
        setClickOutside(true);
      }}
      className='forceGraph'
      nodeVal={(node) => {
        const nodeSize = bySize ? node.follower_count / 30000 : 30;
        return nodeSize;
      }}
      backgroundColor={'transparent'}
      linkColor={(link) => {
        if (!bySize) {
          if (link.dir == 2) {
            return 'rgba(57,255,20,0.2)';
          } else {
            return 'rgba(255,255,255,0.2)';
          }
        } else {
          return 'rgba(255, 255, 255, 0)';
        }
      }}
      graphData={graph}
      ref={fgRef}
      onEngineStop={() => fgRef.current.zoomToFit(400)}
      linkDirectionalArrowLength={(link) => {
        if (link.dir == 2) {
          return 0;
        } else {
          return 10;
        }
      }}
      onNodeClick={(node) => {
        setFilter('all');
        setArtistCard(node);
        handle(node);
      }}
      nodeLabel={(node) => `
  <p style="text-transform:uppercase;font-size:12px;">${node.name}</p>
  
 `}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const imageSize = bySize ? node.follower_count / 30000 : 30;
        const profilePic = new Image();
        node.userName == 'capital_bra' || node.userName == 'haftbefehl'
          ? (profilePic.src = require(`../../images/${node.userName}.png`))
          : (profilePic.src = require(`../../images/${node.userName}.jpg`));
        ctx.save();
        ctx.beginPath();

        ctx.arc(node.x, node.y, imageSize / 2, 0, 2 * Math.PI);
        ctx.fillStyle = 'red';
        ctx.fill();
        ctx.closePath();
        ctx.clip();

        ctx.drawImage(
          profilePic,
          node.x - imageSize / 2,
          node.y - imageSize / 2,
          imageSize,
          imageSize
        );
      }}
    />
  );
};

export default Graph;
