import React, { useEffect, useState } from 'react';
import { getNonRappers } from '../../api/index';
import './norapper.css';
const NoRapper = () => {
  const [currTable, setCurrTable] = useState([]);
  const [initTable, setinitTable] = useState([]);
  useEffect(() => {
    getNonRappers().then((data) => {
      console.log(data);
      setCurrTable(data);
      setinitTable(data);
    });
  }, []);
  const i = 1;
  const [clickOutside, setClickOutside] = useState(true);
  const [nonRapSel, setNonRapSel] = useState();
  const [toggleFolls, setToggleFolls] = useState(false);
  return (
    <div className='container_ranking'>
      <button
        className='optionsButton'
        style={
          clickOutside
            ? { background: 'none' }
            : {
                backgroundColor: '#0ba9ca',
                boxShadow:
                  '0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,0 0 40px #0ba9ca, 0 0 50px #0ba9ca',
              }
        }
        onClick={() => {
          setClickOutside(!clickOutside);
        }}
      >
        Filters
      </button>
      <div className='container_filter_buttons'>
        <button
          className='optionsButton'
          onClick={() => {
            setCurrTable(initTable);
          }}
          style={clickOutside ? { opacity: 0 } : { opacity: 1 }}
        >
          All
        </button>
        <button
          className='optionsButton'
          onClick={() => {
            setCurrTable(
              initTable.filter((el) => {
                return el.category == 'produzenten';
              })
            );
          }}
          style={clickOutside ? { opacity: 0 } : { opacity: 1 }}
        >
          Aestethics and Beats
        </button>
        <button
          className='optionsButton'
          onClick={() => {
            setCurrTable(
              initTable.filter((el) => {
                return el.category == 'creator';
              })
            );
          }}
          style={clickOutside ? { opacity: 0 } : { opacity: 1 }}
        >
          Others
        </button>

        <button
          className='optionsButton'
          onClick={() => {
            setCurrTable(
              initTable.filter((el) => {
                return el.category == 'webseiten';
              })
            );
          }}
          style={clickOutside ? { opacity: 0 } : { opacity: 1 }}
        >
          Corporate and Media
        </button>
        <button
          className='optionsButton'
          onClick={() => {
            setCurrTable(
              initTable.filter((el) => {
                return el.category == 'amiRapper';
              })
            );
          }}
          style={clickOutside ? { opacity: 0 } : { opacity: 1 }}
        >
          International Stars
        </button>
      </div>
      <div className='table_container' onClick={() => setClickOutside(true)}>
        <table className='tidy_table' onClick={() => setClickOutside(true)}>
          <thead>
            <tr>
              <th title='Platz'>Platz</th>
              <th title='UserName'>UserName</th>
              <th title='RapperFollower'>Follows</th>
              <th title='Punkte'>Punkte</th>
            </tr>
          </thead>
          <tbody>
            {currTable &&
              currTable.map((el, i) => {
                return (
                  <tr
                    onClick={() => {
                      setToggleFolls(true);
                      setNonRapSel({ rapper: el, id: i });
                      console.log(nonRapSel);
                    }}
                    key={i}
                  >
                    <td title={i + 1}>{i + 1}</td>
                    <td title={el.userName}>{el.userName}</td>
                    <td title={el.scoreAbs}>{el.scoreAbs}</td>
                    <td title={el.scoreRel}>
                      {el.scoreRel.toLocaleString('en', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    {nonRapSel &&
                    i - nonRapSel.id >= 0 &&
                    toggleFolls &&
                    i < nonRapSel.rapper.followedBy.length + nonRapSel.id ? (
                      <td>
                        {' '}
                        {nonRapSel.rapper.followedBy[i - nonRapSel.id].name}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NoRapper;
