import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const Menu = () => (
    <>
      <p>
        <a href='/' className='href'>
          Home
        </a>
      </p>
      <p>
        <a href='/history' className='href'>
          History
        </a>
      </p>
      <p>
        <a href='/news' className='href'>
          News
        </a>
      </p>
      <p>
        <a href='/iamnotarapper' className='href'>
          Sch****vergleich
        </a>
      </p>
    </>
  );
  return (
    <div className='drapclout__navbar'>
      <div className='drapclout__navbar-links'>
        <div className='drapclout__navbar-links_logo'>
          <a href='/'>
            <img src={logo} id={'logo'} />
          </a>
        </div>
        <div className='drapclout__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='drapclout__navbar-sign'>
        <p>Sign in</p>
        <button type='button'>Sign up</button>
      </div>
      <div className='drapclout__navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine
            color='#fff'
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color='#fff'
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className='drapclout__navbar-menu_container scale-up-center'>
            <div className='drapclout__navbar-menu_container-links'>
              <Menu />
            </div>
            <div className='drapclout__navbar-menu_container-links-sign'>
              <p>Sign in</p>
              <button type='button'>Sign up</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
