import Axios from 'axios';

export const getArtistsData = async () => {
  const URL = '/data';
  const options = {
    method: 'GET',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await Axios(URL, options);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getNonRappers = async () => {
  const URL = '/norapper';
  const options = {
    method: 'GET',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await Axios(URL, options);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getHistoryData = async () => {
  const URL = '/hisdata';
  const options = {
    method: 'GET',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await Axios(URL, options);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getNewsData = async () => {
  const URL = '/newsdata';
  const options = {
    method: 'GET',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await Axios(URL, options);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
