import React, { useState } from 'react';
import './searchBar.css';
const SearchBar = ({
  initArtistList,
  setArtistList,
  artistList,
  setArtistCard,
  setClickOutside,
  clickOutside,
  initialGraph,
  artistCard,
}) => {
  const [drop, setDrop] = useState(false);
  const [dropHeight, setDropHeight] = useState(21);

  const onInputChange = (event) => {
    console.log(event.target.value);
    const newArtistList = initArtistList.filter((option) =>
      option.includes(event.target.value)
    );
    if (newArtistList.length >= 7) {
      setDropHeight(21);
    } else {
      setDropHeight(newArtistList.length * 3);
    }
    setArtistList(newArtistList);
    console.log(initArtistList);
  };
  const handleSearchClick = (el) => {
    console.log(initialGraph);
    console.log(el);
    const node = initialGraph.nodes.find((node) => node.name == el);

    setArtistCard(node);

    console.log(artistCard);
  };

  return (
    <div
      className='search-bar-dropdown'
      style={
        clickOutside ? { height: '3vh' } : { height: `${dropHeight + 3}vh` }
      }
    >
      <input
        className='inputSearch'
        type={'text'}
        placeholder='search'
        onChange={onInputChange}
        onClick={() => {
          setClickOutside(!clickOutside);
        }}
      />
      <div
        className='search-res'
        style={clickOutside ? { height: '0vh' } : { height: `${dropHeight}vh` }}
      >
        {artistList &&
          artistList.map((el, i) => (
            <button
              onClick={(e) => handleSearchClick(e.target.value)}
              key={i}
              value={el}
            >
              {el}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SearchBar;
