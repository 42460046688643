import React from 'react';
import {
  Footer,
  Header,
  FollowSection,
  History,
  News,
  NoRapper,
} from './containers';
import { Navbar } from './components';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const App = () => {
  return (
    <div className='App'>
      <div className='gradient__bg'>
        <Navbar />
        <Router>
          <Routes>
            <Route path='/' element={<FollowSection />} />
            <Route path='/history' element={<History />} />
            <Route path='/news' element={<News />} />
            <Route path='/iamnotarapper' element={<NoRapper />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
